import React, { createContext, useContext, useState } from 'react';
import { TwilioError } from 'twilio-video';
import { User } from 'firebase';
import useSnackbar from '@hooks/useSnackbar';
import { getTimeDiff } from '@helpers';
import useFirebaseAuth from './useFirebaseAuth/useFirebaseAuth';
import usePasscodeAuth from './usePasscodeAuth/usePasscodeAuth';

export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  getToken(name: string, room: string, passcode?: string): Promise<string>;
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  timeout?: number;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks fron being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [timeout, setTimeoutState] = useState(0);
  const { setOpen, setProps } = useSnackbar();
  const { children } = props;

  let contextValue = {
    error,
    setError,
    isFetching,
    timeout,
  } as StateContextType;

  if (process.env.REACT_APP_SET_AUTH === 'firebase') {
    contextValue = {
      ...contextValue,
      ...useFirebaseAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else if (process.env.REACT_APP_SET_AUTH === 'passcode') {
    contextValue = {
      ...contextValue,
      ...usePasscodeAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else {
    contextValue = {
      ...contextValue,
      getToken: async (identity, roomName) => {
        const headers = new window.Headers();
        const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || 'http://localhost:4600/api/token';
        const params = new window.URLSearchParams({ identity, roomName });

        return fetch(`${endpoint}?${params}`, { headers }).then(async (res) => {
          const body = await res.json();

          if (res.status !== 201) {
            throw body;
          }

          const now = new Date();

          const secondDate = new Date(body.end);

          const diffMunites = getTimeDiff({
            firstDate: now,
            secondDate,
          });

          const diffMs = getTimeDiff({
            firstDate: now,
            secondDate,
            format: 'miliseconds',
          });

          window.clearTimeout(timeout!);

          const to = window.setTimeout(
            () => {
              setProps({
                message: `Video conference will be ended in ${
                  diffMunites < 0 ? 5 : 5 - diffMunites
                } munites.`,
                severity: 'warning',
              });
              setOpen(true);
            },
            diffMs < 0 ? Math.abs(diffMs) : 0,
          );

          setTimeoutState(to);
          return body.token as string;
        });
      },
    };
  }

  const getToken: StateContextType['getToken'] = async (name, room) => {
    setIsFetching(true);
    try {
      const res = await contextValue.getToken(name, room);
      setIsFetching(false);
      return res;
    } catch (err) {
      setError(err);
      setIsFetching(false);
      return Promise.reject(err);
    }
  };

  return (
    <StateContext.Provider value={{ ...contextValue, getToken }}>{children}</StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
